@media (max-width: 767px) {
  header .logo {
    background-color: #000!important;
  }

  .product-detail-mobile {
    padding: 0 15px;
  }

  .review .review-detail-mobile {
    padding: 0 15px;
  }

  .slick-container {
    padding: 0 40px;
  }

  .product-date-price {
    padding: 15px 15px;
    border-left: none;
  }

  .product-detail table.detail tbody tr td:first-child {
    width: 100px;
    padding: 0 10px 0 0;
  }

  .actions .datetimepicker {
    text-align: center;
  }

  .extra-content {
    padding-top: 15px;
  }
}

@media (max-width: 767px) {
  header .logo {
    background-image: url(../../images/frontend/logo-fixed.png);
  }
}