body {
  font-family: 'Proza Libre', sans-serif !important;
  color: #333 !important;
  padding-top: 65px;
}

b, strong {
  font-family: 'Proza Libre', sans-serif;
  font-weight: 700;
}

a {
  color: #333 !important;
}

a:hover, a:focus {
  color: #333 !important;
  text-decoration: none;
}

footer {
  font-family: 'Proza Libre', sans-serif !important;
  padding: 0;
}

a:visited {
  color: #333;
}

.site-footer .site-info .widget .widgettitle {
  font-size: 12px;
  color: #FFF !important;
}

.site-footer .site-info .widget:nth-child(2) p {
  font-family: Caudex, sans-serif;
  font-size: 14px;
  margin: 0;
  line-height: 1.2;
}

.site-footer {
  margin-top: 0;
}

.site-footer a {
  color: #FFF !important;
}

h1, h2, h3 {
  font-family: 'Proza Libre', sans-serif !important;
  color: #333 !important;
}

body.fixed header .logo {
  height: 65px !important;
  background-position: center center !important;
  background-size: 50px;
  background-color: transparent !important;
  background-image: url(../images/logo-fixed.png);
}

.school-color {
  color: #ff6400;
}

.cont-menu #nav > ul > li.prenotaMenu {
  background-color: #6595A5 !important;
  color: white !important;
}

.cont-menu #nav > ul > li.prenotaMenu > a {
  color: white !important;
  padding-right: 10px;
  padding-left: 10px;
}

.produxt-box {
  padding: 10px;
}

.produxt-box .product-footer .product-label {
  font-family: 'Proza Libre', sans-serif;
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin: 10px 0;
}

.produxt-box .product-footer .product-description {
  font-family: Georgia, Serif, serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  color: #333;
  text-align: center;
  margin: 10px 0;
}

.produxt-box .product-header .product-image {
  max-width: 100%;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.produxt-box:hover .product-header .product-image {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.product-detail {
  padding: 0 40px 0 0;
}

.product-detail .sub-title {
  font-family: 'Proza Libre', sans-serif;
  text-transform: uppercase;
}

.product-detail table {
  width: 100%;
}

.product-detail table tbody tr td {
  vertical-align: top;
  padding-bottom: 15px;
}

.product-detail table.detail tbody tr td:first-child {
  width: 150px;
}

.product-detail table h4 {
  font-family: 'Proza Libre', sans-serif;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 5px;
}

.product-date-price {
  padding: 0 0 0 40px;
  border-left: 2px dashed #333;
}

.product-date-price #product-date {
  text-align: center;
  padding: 20px 0;
}

.product-price {
  font-family: 'Proza Libre', sans-serif;
  margin-bottom: 30px;
}

.product-price .product-price-total {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.price-box {
  margin-bottom: 10px;
}

.price-box .price {
  font-family: 'Proza Libre', sans-serif;
  font-weight: bold;
}

.price-box .input-group {
  top: -6px;
}

.price-box .input-group input {
  font-family: 'Proza Libre', sans-serif;
  text-align: center;
}

.product-price-total {
  border-top: 2px solid #333;
}

.product-price-button {
  font-weight: bold;
  font-size: 30px;
  background-color: transparent;
  border: 2px solid #333;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  display: block;
  text-align: center;
}

.product-price-button:hover, .product-price-button:focus {
  color: inherit;
}

.product-price-button.back {
  color: #c10909;
  border: 2px solid #c10909;
  font-weight: normal;
}

.extra-box {
  margin-bottom: 20px;
}

.extra-box .extra-image {
  padding-top: 5px;
}

.extra-box .extra-image img {
  width: 100%;
}

.extra-box .extra-content h3 {
  margin-top: 0;
}

.extra-box.extra-tour {
  padding-top: 10px;
  padding-bottom: 10px;
}

.extra-box.extra-tour .tour-overlay {
  background: red;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  transition: opacity .5s;
}

.extra-box.extra-tour .actions {
  font-family: 'Proza Libre', sans-serif;
}

.extra-box.extra-tour .tour-detail {
  font-family: 'Proza Libre', sans-serif;
}

.slots-header {
  padding-top: 10px;
  padding-bottom: 10px;
}

.slots-detail {
  display: flex;
  flex-wrap: wrap;
}

.slots-detail .slots-availability {
  background-color: #999;
  border-radius: 3px;
  width: 70px;
  height: 70px;
  margin: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  cursor: not-allowed;
}

.slots-detail .slots-availability.available {
  background-color: #57c16e;
  cursor: pointer;
}

.slots-detail .slots-availability.available.selected {
  background-color: #578cda;
}

.review {
  font-family: 'Proza Libre', sans-serif;
}

.review .review-detail {
  padding: 0 40px 0 0;
}

.review .review-detail .review-image {
  width: 100%;
}

.review .review-detail .review-label {
  font-weight: bold;
}

.review .review-detail .total {
  border-top: 2px dashed #333;
  padding-top: 20px;
}

.row.response {
  font-family: 'Proza Libre', sans-serif;
}

.row.response a {
  text-decoration: underline;
}

.spinner {
  margin: 25px auto 25px;
  width: 70px;
  text-align: center;
  display: none;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.product-date-price form input,
.product-date-price form select {
  margin-bottom: 4px;
}

.select2-container {
  width: 100% !important;
  margin-bottom: 4px;
  font-family: 'Proza Libre', sans-serif;
  text-align: left;
  font-size: 14px;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  display: flex;
  align-items: center;
}

.slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
}

.slick-slide img {
  display: flex;
}

.slick-prev:before, .slick-next:before {
  color: #333;
}

.alert {
  font-family: 'Proza Libre', sans-serif;
}

input.invalid, select.invalid, textarea.invalid {
  border-color: #c10909;
}

input.invalid::-webkit-input-placeholder, textarea.invalid::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c10909;
}

.product-price .automatic {
  display: none;
}

@media (max-width: 767px) {
  header .logo {
    background-color: #000 !important;
  }
  .product-detail-mobile {
    padding: 0 15px;
  }
  .review .review-detail-mobile {
    padding: 0 15px;
  }
  .slick-container {
    padding: 0 40px;
  }
  .product-date-price {
    padding: 15px 15px;
    border-left: none;
  }
  .product-detail table.detail tbody tr td:first-child {
    width: 100px;
    padding: 0 10px 0 0;
  }
  .actions .datetimepicker {
    text-align: center;
  }
  .extra-content {
    padding-top: 15px;
  }
}

@media (max-width: 767px) {
  header .logo {
    background-image: url(../../images/frontend/logo-fixed.png);
  }
}
